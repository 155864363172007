/** @jsx jsx */
import React, { useEffect, useState } from 'react'
import { jsx, Flex, IconButton, Button } from 'theme-ui'
import Text from '~/components/IntlComponents/Text'
import Heading from '~/components/IntlComponents/Heading'
import YellowStar from '~/assets/images/icons/yellow-star.svg'
import UsWeekly from '~/assets/images/logos/us-weekly-logo.svg'
import Uproxx from '~/assets/images/logos/uproxx-logo.svg'
import Shape from '~/assets/images/logos/shape-logo.svg'
import Forbes from '~/assets/images/logos/Forbes.svg'
import TechCrunch from '~/assets/images/logos/TechCrunch.svg'
import Lookout from '~/assets/images/logos/Lookout.svg'
import FoodNavigator from '~/assets/images/logos/Food-Navigator.svg'
import ArrowLeft from '~/assets/images/icons/arrow-left.svg'
import ArrowRight from '~/assets/images/icons/arrow-right.svg'

const Logos = [
  <Shape />,
  <Uproxx />,
  <UsWeekly />,
  <TechCrunch />,
  <FoodNavigator />,
  <Forbes />,
  <Lookout />,
]

const arrowStyles = {
  maxWidth: '32px',
  maxHeight: '32px',
  minWidth: '32px',
  minHeight: '32px',
  borderRadius: '50%',
  mx: ['8px', '32px'],
  circle: {
    fill: 'japaneseLaurel',
    stroke: 'japaneseLaurel',
  },
  path: {
    fill: 'schoolBusYellow',
  },
}

function PressCopyAndQuotes({ section: { title, testimonials } }) {
  const [activeTestimonialIdx, setActiveTestimonialIdx] = useState(0)
  const [isPaused, setIsPaused] = useState(false)
  // Clearing the timeout fixes the hot reload issue, it looked like a mem leak.

  useEffect(() => {
    if (isPaused) return
    const timeout = setTimeout(() => {
      if (activeTestimonialIdx < Logos.length - 1) {
        setActiveTestimonialIdx(x => x + 1)
      } else {
        setActiveTestimonialIdx(0)
      }
    }, 3000)
    return () => clearTimeout(timeout)
  }, [activeTestimonialIdx, isPaused])

  return (
    <>
      {/* Header & reviews */}
      <Flex
        sx={{
          flexDirection: 'column',
          textAlign: ['center', 'left'],
          width: ['80%', '40%'],
          maxWidth: '527px',
          minWidth: '300px',
          mt: ['25px', 0],
          color: 'japaneseLaurel',
        }}
      >
        <Heading
          as="h2"
          sx={{
            textTransform: 'uppercase',
            lineHeight: ['2rem', '3rem'],
            fontSize: ['2rem', '3rem'],
          }}
        >
          {title}
        </Heading>

        <Flex
          sx={{
            flexDirection: ['column', 'row'],
            alignItems: 'center',
            mt: ['24px', '20px'],
            mb: ['40px', 0],
          }}
        >
          <Flex
            sx={{
              mb: ['8px', 0],
              alignItems: 'center',
              '> svg': {
                mr: '8px',
                color: 'japaneseLaurel',
              },
            }}
          >
            <YellowStar />
            <YellowStar />
            <YellowStar />
            <YellowStar />
            <YellowStar />
          </Flex>
          <Text sx={{ fontSize: ['.8rem', '1rem'], fontWeight: 900 }}>
            4.8 / 5.0 (10,200 reviews and counting!)
          </Text>
        </Flex>
      </Flex>
      {/* Testimonials */}

      <Flex
        sx={{
          flexDirection: 'column',
          width: ['80%', '40%'],
          maxWidth: '527px',
          minHeight: ['none', '190px'],
          justifyContent: 'center',
          position: 'relative',
        }}
      >
        {/* Hidden button for UsableNet to control pause/play states
        <IconButton
          sx={{
            height: '50px',
            width: '100%',

            cursor: 'pointer',
            position: 'absolute',
            top: -40,

            right: 0,
          }}
          onClick={() => setIsPaused(!isPaused)}
        >
          <VideoPlayerSVG />
        </IconButton> */}
        <Text
          sx={{
            mb: '1.5rem',
            textAlign: 'center',
            minHeight: ['72px', '48px'],
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: 'japaneseLaurel',
            fontWeight: 900,
          }}
        >
          {testimonials[activeTestimonialIdx]?.quote ?? ''}
        </Text>
        <Flex
          sx={{
            justifyContent: 'space-between',
            alignItems: 'center',
            position: 'relative',
            svg: {
              cursor: 'pointer',
              width: ['50%', 'auto'],
              maxHeight: '6.25rem',
              minHeight: '6.25rem',
              transition: 'all 0.2s ease-in-out',
              fill: 'japaneseLaurel',
              color: 'japaneseLaurel',
              // for Forbes logo need to manually target the color to path
              '#forbesBackground': {
                fill: 'transparent',
              },
              '.forbesText': {
                fill: 'japaneseLaurel',
              },
            },
            '#lookoutLogo > path': {
              fill: 'japaneseLaurel',
            },
          }}
          onMouseEnter={() => setIsPaused(true)}
          onMouseLeave={() => setIsPaused(false)}
        >
          <ArrowLeft
            sx={arrowStyles}
            onClick={() => {
              if (activeTestimonialIdx > 0) {
                setActiveTestimonialIdx(x => x - 1)
              } else {
                setActiveTestimonialIdx(Logos.length - 1)
              }
            }}
          />
          {Logos[activeTestimonialIdx]}
          <ArrowRight
            sx={arrowStyles}
            onClick={() => {
              if (activeTestimonialIdx < Logos.length - 1) {
                setActiveTestimonialIdx(x => x + 1)
              } else {
                setActiveTestimonialIdx(0)
              }
            }}
          />
        </Flex>
        {/* Hidden button for UsableNet to control pause/play states */}
        <IconButton
          sx={{
            visibility: 'hidden',
            height: '40px',
            width: '100%',
            cursor: 'pointer',
            position: 'absolute',
            bottom: -50,
            zIndex: 4,
          }}
          onClick={() => setIsPaused(!isPaused)}
        >
          {isPaused && (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              id="play-icon"
              version="1.1"
              height="50"
              width="50"
              viewBox="0 0 1200 1200"
            >
              <title>Play Press Testimonials</title>
              <path
                d="M 600,1200 C 268.65,1200 0,931.35 0,600 0,268.65 268.65,0 600,0 c 331.35,0 600,268.65 600,600 0,331.35 -268.65,600 -600,600 z M 450,300.45 450,899.55 900,600 450,300.45 z"
                id="path16995"
              />
            </svg>
          )}

          {!isPaused && (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              id="pause-icon"
              version="1.1"
              height="50"
              width="50"
              viewBox="0 0 1200 1200"
            >
              <title>Pause Press Testimonials</title>
              <path
                id="path15778"
                d="M 600,0 C 268.62914,0 0,268.62914 0,600 c 0,331.37086 268.62914,600 600,600 331.37086,0 600,-268.62914 600,-600 C 1200,268.62914 931.37086,0 600,0 z m -269.16515,289.38 181.71397,0 0,621.24 -181.71397,0 0,-621.24 z m 356.61633,0 181.71399,0 0,621.24 -181.71399,0 0,-621.24 z"
              />
            </svg>
          )}
        </IconButton>
      </Flex>
    </>
  )
}

export default PressCopyAndQuotes
