import { graphql } from 'gatsby'
import React from 'react'
import Home from '~/components/Home/index'
import Layout from '~/components/Layout'
import Metadata from '~/components/Metadata'

const IndexPage = ({ data, recipientEmail }) => {
  return data && data.home ? (
    <Layout isHome logoColors={['#FFEE00', '#FAA819']}>
      <Metadata title={data.home.title} />
      <Home recipientEmail={recipientEmail} page={data.home}></Home>
    </Layout>
  ) : null
}

export default IndexPage

export const query = graphql`
  query IndexPage($locale: String) {
    home: contentfulHome(slug: { eq: "home" }, node_locale: { eq: $locale }) {
      id
      slug
      node_locale
      title
      heading
      subheading

      heroCallToAction {
        ...CallToActionFragment
      }

      productGrid {
        title

        showAllGridItemsOnMobile
        hideFeaturedVariant
        subtitle {
          childMarkdownRemark {
            excerpt
          }
        }
        variants {
          sku
          size
          price
          soldOut
          discountPercentage
          productGridSticker {
            title
            fill
            stroke
          }
          productGridImage {
            gatsbyImageData(quality: 90)
            file {
              contentType
              url
            }
          }
          product {
            name
            slug
            node_locale
            yotpoId
            productType {
              slug
              productGroup
            }
            waitlistPopup {
              heading: shortText
              body: longText {
                childMarkdownRemark {
                  html
                }
              }
            }
            images {
              title
              gatsbyImageData(quality: 90)
            }
          }
        }
        featuredVariant {
          sku
          size
          price
          soldOut
          productGridSticker {
            title
            fill
            stroke
          }
          productGridImage {
            gatsbyImageData(quality: 90)
            file {
              contentType
              url
            }
          }
          product {
            slug
            name
            yotpoId
            productType {
              slug
              productGroup
            }
            images {
              title
              gatsbyImageData(quality: 90)
            }
          }
        }
      }
      categorySelection {
        internal {
          type
        }
        name
        slug
        mainImage {
          id
          gatsbyImageData(width: 500, quality: 80)
          title
        }
      }
      quoteSectionTitle
      quoteCards {
        id
        attributionName
        attributionTitle
        quote
        photo {
          id
          title
          gatsbyImageData(width: 200, quality: 100)
        }
      }
      articleHighlightList {
        ...ArticleCardFragment
      }
      pageSections {
        name
        slug
        title
        images {
          id
          gatsbyImageData(width: 200, quality: 100)
          title
          file {
            contentType
            url
            details {
              image {
                width
              }
            }
          }
        }
        testimonials {
          id
          quote: shortText
          title
        }
      }
      pageHighlightList {
        ...TextSectionWithButton
      }
      heroVideoFallback {
        id
        file {
          contentType
          url
        }
      }
      heroImage {
        id
        file {
          contentType
          url
        }
        title
        gatsbyImageData(quality: 80)
      }
      mobileHeroVideo {
        id
        file {
          contentType
          url
        }
        title
      }
      bowlImage {
        id
        gatsbyImageData(width: 700, quality: 80)
        title
      }
      maskedVideo {
        id
        title
        file {
          contentType
          url
        }
      }
      valuePropHeading
      valuePropSubheading
      valuePropImage {
        valuePropImage {
          title
          gatsbyImageData(width: 500, quality: 80)
        }
      }
      productHighlightList {
        ...TextSectionWithButton
      }
      generalHighlight {
        ...TextSectionWithButton
      }

      marqueeCopy
      marqueeIcons {
        gatsbyImageData(width: 200, quality: 80)
      }
      videoTestimonialTitle
      videoTestimonials {
        ...VideoTestimonial
      }
    }
  }
`
