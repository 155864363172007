/** @jsx jsx */
import { GatsbyImage } from 'gatsby-plugin-image'
import { jsx, useThemeUI, Container } from 'theme-ui'
import React, { useLayoutEffect, useState } from 'react'
import ImgLoader from '../Generic/ImgLoader'
import ImageAndTextSection from '~/components/Product/Page/ImageAndTextSection'
import { WigglySectionDivider } from '~/components/Generic/SectionDivider/WigglySectionDivider'
import { MobileWigglySectionDivider } from '~/components/Generic/SectionDivider/MobileWigglySectionDivider'
import { renderImageOrVideo } from '~/utils/render-image-or-video'

const HeroSection = ({
  page: {
    heading,
    subheading,
    heroImage,
    heroVideoFallback,
    localButtonStyle,
    mobileHeroVideo,
    heroCallToAction,
  },
  isMobile,
}) => {
  const { theme } = useThemeUI()

  const mobileVideo = !!mobileHeroVideo ? mobileHeroVideo : heroVideoFallback

  return (
    <Container
      p={0}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: ['center', 'flex-start'],
        width: '100%',
        paddingX: '0 !important',
        position: 'relative',
        height: ['64vh', '92vh', '95vh'],
        backgroundColor: 'primary',
      }}
    >
      {/* Desktop video */}
      {!isMobile ? (
        <>
          <ImageAndTextSection
            cta={heroCallToAction}
            headingText={heading}
            subHeadingText={subheading}
            containerStyle={{
              display: ['none', 'flex'],
              mt: ['125px', '127px'],
              color: 'override.hero',
              paddingBottom: [0, 0, 120],
              maxWidth: 1440,
              width: ['auto', (626 + 50) * 2],
              zIndex: 2,
            }}
            headingForwardSx={{
              fontSize: '4rem !important',
              maxWidth: '13ch',
              lineHeight: '4.5rem !important',
              color: 'secondary',
            }}
            subHeadingForwardSx={{
              fontSize: ['1rem', '1rem', '1rem'],
              fontWeight: 800,
              my: [0, '1rem'],
              maxWidth: '45ch',
              color: 'secondary',
            }}
            buttonStyle={{
              width: '100%',
              maxWidth: '320px',
              color: '#FFEE00',
              backgroundColor: 'override.press.button.back',
              ':hover': {
                color: 'override.press.button.hover.color',
                backgroundColor: '#FFEE00',
              },
            }}
          />
          <div
            sx={{
              position: 'absolute',
              display: ['none', 'block'],
              width: '100%',
              height: '120%',
              left: 0,
              bottom: [0, '-150px'],
            }}
          >
            {renderImageOrVideo(heroImage, {
              VideoComponent: video => (
                <video
                  sx={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    objectPosition: '90%',
                    display: ['none', 'block'],
                  }}
                  src={`https:${video.file.url}`}
                  autoPlay
                  playsInline
                  loop
                  muted
                  controls={false}
                />
              ),
              ImageComponent: image => (
                <ImgLoader
                  forwardSx={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    objectPosition: '90%',
                    display: ['none', 'block'],
                  }}
                  image={image}
                ></ImgLoader>
              ),
            })}
          </div>
        </>
      ) : (
        // {/*  Mobile Video  */}
        <div
          sx={{
            position: 'absolute',
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            height: '100%',
          }}
        >
          {renderImageOrVideo(mobileVideo, {
            VideoComponent: video => (
              <video
                sx={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                  position: 'relative',
                  display: ['block', 'none'],
                }}
                src={`https:${video.file.url}`}
                autoPlay
                playsInline
                loop
                muted
                controls={false}
              />
            ),
            ImageComponent: image => (
              <ImgLoader
                forwardSx={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                  position: 'relative',
                  display: ['block', 'none'],
                }}
                image={image}
              ></ImgLoader>
            ),
          })}
        </div>
      )}
      {/* This one subverts the pattern of having these dividers at the top level because of the transparency & video overlap */}
      {/* Would be nice to refactor this into one component but right now I'm not entirely sure how that fits with all designs */}
      <div
        sx={{
          position: 'absolute',
          bottom: 0,
          width: '100%',
          zIndex: '10px',
          transform: 'translate3d(0,0,100px)',
        }}
      >
        <WigglySectionDivider
          forwardSx={{
            display: ['none', 'flex'],
          }}
          small
          topColor="transparent"
          bottomColor={theme.colors.frenchPass}
        />
      </div>
      <MobileWigglySectionDivider
        forwardSx={{
          position: 'absolute',
          bottom: '-4px',
        }}
        topColor="transparent"
        medium
        bottomColor={theme.colors.frenchPass}
      />
    </Container>
  )
}

export default HeroSection
